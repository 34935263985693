declare const Buffer;
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { pcmEncode, downsampleBuffer } from './helpers/audioUtils';
import * as createHash from 'create-hash';
import { createPresignedURL } from './helpers/aws-signature-v4'; // to generate our pre-signed URL
import * as marshaller from '@aws-sdk/eventstream-marshaller'; // for converting binary event stream messages to and from JSON
import * as util_utf8_node from '@aws-sdk/util-utf8-node'; // utilities for encoding and decoding UTF8
import MicrophoneStream from 'microphone-stream'; // collect microphone input as a stream of raw bytes
import { ActivatedRoute } from '@angular/router';
import { environment } from '../environments/environment';
// our converter between binary event streams messages and JSON
const eventStreamMarshaller = new marshaller.EventStreamMarshaller(
  util_utf8_node.toUtf8,
  util_utf8_node.fromUtf8
);
declare const Hls: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  channels: { name: string; logo: string; url: string }[] = [];
  channel_query;
  filteredChannels = [...this.channels]; // Initialize with all channels
  videoUrl: string | null = null; // Holds the selected channel URL for the player view

  title = 'LIA';
  languageCode = 'en-US';
  region = 'us-east-1';
  sampleRate = 44100;
  transcription = '';
  socket;
  micStream;
  socketError = false;
  transcribeException = false;
  errorText: '';
  constructor(private route: ActivatedRoute,
    private http: HttpClient) { }
    filterChannels(event: any): void {
      const query = event.target.value.toLowerCase();
      console.log(query)
      this.channel_query=query;
      this.filteredChannels = this.channels.filter(channel =>
        channel.name.toLowerCase().includes(query)
      );
    }
ngOnInit(){
  this.route.queryParams.subscribe(params => {
    const username = params['username'];
    const language = params['language'];
    const tv = params['tv'];
    if (username ) {
      console.log('present Lia')
    this.startRecording();
    if(language){
      console.log('params', params)
      this.languageCode=language;
      console.log(language);
    }
    }else{

    }
if(tv){
  this.loadChannels();
}
});

}


  startRecording() {
    console.log('recording');
    window.navigator.mediaDevices.getUserMedia({
      video: false,
      audio: true
  })
  
  // ...then we convert the mic stream to binary event stream messages when the promise resolves 
  .then(this.streamAudioToWebSocket) 
  .catch(function (error) {
      console.log('There was an error streaming your audio to Amazon Transcribe. Please try again.', error);
  });
  }
  streamAudioToWebSocket = (userMediaStream) => {
    //let's get the mic input from the browser, via the microphone-stream module
    console.log('start streamAudioToWebSocket');
    this.micStream = new MicrophoneStream();
    this.micStream.setStream(userMediaStream);
    console.log('start streamAudioToWebSocket22222');
    // Pre-signed URLs are a way to authenticate a request (or WebSocket connection, in this case)
    // via Query Parameters. Learn more: https://docs.aws.amazon.com/AmazonS3/latest/API/sigv4-query-string-auth.html
    let url = this.createPresignedUrlNew();
    console.log('start streamAudioToWebSocket333333');
    //open up our WebSocket connection
    this.socket = new WebSocket(url);
    this.socket.binaryType = 'arraybuffer';
    console.log('start streamAudioToWebSocket44444');
    // when we get audio data from the mic, send it to the WebSocket if possible
    this.socket.onopen = () => {
      this.micStream.on('data', rawAudioChunk => {
        // the audio stream is raw audio bytes. Transcribe expects PCM with additional metadata, encoded as binary
        let binary = this.convertAudioToBinaryMessage(rawAudioChunk);

        if (this.socket.OPEN) this.socket.send(binary);
      });
    };
    console.log('start streamAudioToWebSocket5555');
    // handle messages, errors, and close events
    this.wireSocketEvents();
  }
  createPresignedUrlNew = () => {
    
    let endpoint = 'transcribestreaming.' + this.region + '.amazonaws.com:8443';
    console.log('start createPresignedUrlNew start '+ endpoint );
    // get a preauthenticated URL that we can use to establish our WebSocket
    return createPresignedURL(
      'GET',
      endpoint,
      '/stream-transcription-websocket',
      'transcribe',
      createHash('sha256').update('', 'utf8').digest('hex'),
      {
        key: environment.accessKeyID,
        secret: environment.accessSecret,
        protocol: 'wss',
        expires: 15,
        region: 'us-east-1',
        query:
          'language-code=' +
          this.languageCode +
          '&media-encoding=pcm&sample-rate=' +
          this.sampleRate
      }
    );

  };
  getAudioEventMessage = (buffer) => {
    // wrap the audio data in a JSON envelope
    return {
      'headers': {
        ':message-type': {
          type: 'string',
          value: 'event'
        },
        ':event-type': {
          type: 'string',
          value: 'AudioEvent'
        }
      },
      body: buffer
    };
  }
  convertAudioToBinaryMessage = (audioChunk) => {
    let raw = MicrophoneStream.toRaw(audioChunk);

    if (raw == null) return;

    // downsample and convert the raw audio bytes to PCM
    let downsampledBuffer = downsampleBuffer(raw, this.sampleRate);
    let pcmEncodedBuffer = pcmEncode(downsampledBuffer);

    // add the right JSON headers and structure to the message
    let audioEventMessage = this.getAudioEventMessage(
      Buffer.from(pcmEncodedBuffer)
    );

    //convert the JSON object + headers into a binary event stream message
    // @ts-ignore
    let binary = eventStreamMarshaller.marshall(audioEventMessage);

    return binary;
  }
  closeSocket = () => {
    if (this.socket.OPEN) {
      this.micStream.stop();

      // Send an empty frame so that Transcribe initiates a closure of the WebSocket after submitting all transcripts
      let emptyMessage = this.getAudioEventMessage(Buffer.from(new Buffer([])));
      // @ts-ignore
      let emptyBuffer = eventStreamMarshaller.marshall(emptyMessage);
      this.socket.send(emptyBuffer);
    }
  }
  handleEventStreamMessage = (messageJson) => {
    let results = messageJson.Transcript.Results;

    if (results.length > 0) {
      if (results[0].Alternatives.length > 0) {
        let transcript = results[0].Alternatives[0].Transcript;

        // fix encoding for accented characters
        transcript = decodeURIComponent(escape(transcript));

        // update the textarea with the latest result
        console.log('transcript', transcript);

        // if this transcript segment is final, add it to the overall transcription
        if (!results[0].IsPartial) {
          //scroll the textarea down

          this.transcription += transcript + '\n';
          window.parent.postMessage(transcript, '*');
        }
      }
    }
  }
  wireSocketEvents = () => {
    // handle inbound messages from Amazon Transcribe
    this.socket.onmessage = message => {
      //convert the binary event stream message to JSON
      let messageWrapper = eventStreamMarshaller.unmarshall(
        Buffer(message.data)
      );
      let messageBody = JSON.parse(
        String.fromCharCode.apply(String, messageWrapper.body)
      );
      if (messageWrapper.headers[':message-type'].value === 'event') {
        this.handleEventStreamMessage(messageBody);
      } else {
        this.transcribeException = true;
        console.log(messageBody.Message);
        // toggleStartStop();
      }
    };

    this.socket.onerror = function() {
      this.socketError = true;
      console.log('WebSocket connection error. Try again.');
      // toggleStartStop();
    };

    this.socket.onclose = closeEvent => {
      this.micStream.stop();

      // the close event immediately follows the error event; only handle one.
      if (!this.socketError && !this.transcribeException) {
        if (closeEvent.code != 1000) {
          console.log('error' + closeEvent.reason);
        }
        // toggleStartStop();
      }
    };
  }
  //end lia

  //start channels
  loadChannels() {
    console.log('loading channnels')
    const playlistUrl = 'assets/index.m3u'; // local M3U8 file
  
    this.http.get(playlistUrl, { responseType: 'text' }).subscribe((data) => {
      this.channels = this.parsePlaylist(data);
    });
  }
  
  /**
   * Parse the M3U8 playlist into channel objects
   */
   parsePlaylist(data: string): { name: string; logo: string; url: string }[] {
    // List of channels to remove, by exact name
    const REMOVE_LIST = [
      '00s Replay',
      '1+1 (1080p)',
      '1+2',
      '1Music Channel Hungary (576p)',
      '1Yes News (720p) [Not 24/7]',
      '2A TV (720p) [Not 24/7]',
      '2M Monde (360p)',
      '2S TV (1080p) [Not 24/7]',
      '3/24 (1080p)',
      '3ABN Canada',
      '3sat (720p) [Geo-blocked]',
      '3 Stones TV (240p) [Not 24/7]',
      '4E (1080p)',
      '4UV',
      '5Días TV (720p) [Not 24/7]',
      '5 канал',
      '5 канал (1080p) [Not 24/7]',
      'like Gecko) Mobile/15E148",5 ԹԻՎԻ (480p) [Not 24/7]',
      'like Gecko) Mobile/15E148",5 ԹԻՎԻ ՊԼՅՈՒՍ (480p) [Not 24/7]',
      '7/8 TV (1080p)',
      '7ala TV [Geo-blocked]',
      '7ALimoges (1080p) [Not 24/7]',
      '7D7 (1080p)',
      '7 Info (1080p) [Not 24/7]',
      '7S Music (576p) [Not 24/7]',
      '7 TV',
      '8 La Marina TV (576p)',
      '8NTV (1080p)',
      '8TV (1080p)',
      '8 канал (Витебск) (576p) [Not 24/7]',
      '9Gem (720p) [Geo-blocked]',
      '9Go! (720p) [Geo-blocked]',
      '9 la Loma TV (1080p)',
      '9Life (720p) [Geo-blocked]',
      '9Rush (720p) [Geo-blocked]',
      '9X Jalwa',
      '9x Jhakaas',
      '9X Tashan',
      '9XM (480p)',
      '9 Волна (Черкесск)',
      '10 Bold (720p) [Geo-blocked]',
      '10 Channel (720p)',
      '10 Peach (720p) [Geo-blocked]',
      '10 TV (1080p) [Not 24/7]',
      '11 Kanal (720p)',
      '12tv',
      '12 Visión Encarnación (1080p) [Not 24/7]',
      '13 Festival (1080p)',
      '13 Humor (1080p)',
      '13 Kids (1080p)',
      '13Max Televisión (1080p)',
      '13 Prime (720p)',
      '13 Teleseries (720p)',
      '15tv Sabinas (XHSDD-TDT) (360p)',
      '16 Anni e Incinta Italy (720p)',
      '20 Mediaset [Geo-blocked]',
      '20 Minutes TV (1080p)',
      '24/7 Canal de Noticias',
      '24 Horas (720p) [Not 24/7]',
      '24KZ (720p) [Not 24/7]',
      '24 News (1080p) [Not 24/7]',
      '24 News HD (1080p) [Not 24/7]',
      '24 TV',
      '27 TwentySeven [Geo-blocked]',
      '30A Investment Pitch',
      '30A Lionel Nation TV',
      '30A Ridiculous TV (720p)',
      '28 kanala (720p) [Not 24/7]',
      '43 канал (Туапсе) (720p)',
      '45 TV (720p)',
      '48 Hours',
      '70s Cinema',
      '80s Rewind',
      "90's Kids",
      '90s Throwback',
      '92 News UK (576p)',
      '98.1 Pearl FM (720p)',
      '99TV (720p) [Not 24/7]',
      '100% News (576p)',
      '101tv Antequera',
      '101tv Cádiz (1080p)',
      '101tv Málaga (1080p)',
      '101tv Ronda (1080p)',
      '101tv Sevilla (1080p)',
      '111 TV (720p)',
      '247 Retro TV (432p) [Not 24/7]',
      '313 Digital (1080p) [Not 24/7]',
      '504 TV (720p)',
      '947 Joburg (720p) [Not 24/7]',
      '1001 Noites (720p) [Not 24/7]',
      '90210',
      ':24 (1080p)',
      ':Šport (1080p)',
      '¡OPA! (1080p)',
      'A1 TV Rajasthan (720p)',
      'A2i Music (720p) [Not 24/7]',
      'A2i Naija (720p) [Not 24/7]',
      'A7TV (1080p) [Not 24/7]',
      'A24 (1080p) [Not 24/7]',
      'A Haber',
      'A Million Little Things',
      'A News (1080p) [Not 24/7]',
      'A Nove TV (1080p) [Not 24/7]',
      'A One TV [Not 24/7]',
      'À prendre ou à laisser',
      'À Punt (720p)',
      'A Spor (1080p) [Not 24/7]',
      'A Türk Izmir (360p)',
      '黑龙江 (1080p)',
      '黑龙卫视 (1080p)',
      '鹤壁新闻综合 (480p) [Not 24/7]',
      'ABN Andhra Jyoti (720p) [Not 24/7]',
      'Action Drama',
      'Adult Swim Daily Animated (1080p) [Geo-blocked]',
      'Simsalabim Sabrina',
      'Ski TV (1080p) [Not 24/7]',
      'Rete 4 [Geo-blocked]',
      'Revry Europe (Frequency backend)',
      'Ric+ (1080p)',
      'Ridiculousness',
      'Ridiculousness Italy (720p)',
      'RiffTrax',
      'Tweede Kamer: Vondelingkamer (1080p) [Not 24/7]',
      'like Gecko) Chrome/108.0.0.0 Safari/537.36",BFM Grand Lille (1080p)',
      'Rakuten Action Movies',
      'Rakuten Family',
      'Realmadrid TV',
      'Red Shoe Diaries',
      'Radical Docs',
      'Palmar TV',
      'Parker Lewis Der Coole von der Schule',
      'PBS Kids Alaska (1080p)',
      'VTV9 (1080p)',
      'Walker Texas Ranger',
      'WB Kids Français',
      '龙岩综合 (540p)',
      '静宁综合 (1080p)',
      '迪庆藏语 (576p)',
      '耀才財經台 (576p)',
      '滁州市广播电视台 新闻综合频道 (1080p)',
      'ABC TV Plus (720p)',
      'Angelus TV (720p)',
      'Anger Management',
      'Antiques Road Show UK',
      'Apple68 TV (1080p)',
      'Assassination Classroom',
      'MyTime Movie Network',
      'Number 1 Dance (720p)',
      'Viper',
      'ViuTV',
      'ViX Jajaja (1080p)',
      'ViX Parejas (1080p)',
      "Vevo '80s",
      'Strongman',
      'The Twilight Zone',
      'The Walking Dead Universe',
      'The Walking Dead en Español',
      'Teletoon+ (Poland) (1080p)',
      '龙口生活 (576p)',
      '龙口新闻综合 (576p)',
      '龙口图文 (576p)',
      '黑龙江卫 (1080p)',
      '黑龙卫视 (720p)',
      '鳳凰資訊',
      '鳳凰中文',
      '高清电影 (1080p)',
      '高台电视台 (1080p)',
      '香港國際財經台',
      '风尚购物 (1080p)',
      '青海卫视 (1080p)',
      '青州综合 (576p)',
      '青州生活 (576p)',
      '青州文化旅游 (576p)',
      '霸州新聞頻道 (576p)',
      '霸州文化頻道 (576p)',
      '霍山综合 (576p)',
      '隆化综合 (576p)',
      '隆化影视 (576p)',
      '陕西卫视 (576p)',
      '陕西卫视 (540p)',
      '金鹰卡通 (576p)',
      '重庆卫视 (1080p)',
      '酒泉新闻综合 (576p)',
      '邵东综合 (576p)',
      '邯郸科教',
      '邯郸新闻',
      '邯郸公共',
      '邢台综合',
      '邢台城生',
      '邗江资讯 (576p)',
      '通州电视台 (720p)',
      '迪庆综合 (1080p)',
      '辽宁卫视 (1080p)',
      '赵县电视二套 (576p)',
      '赵县电视二套 (360p)',
      '赵县电视一套 (576p)',
      '赵县电视一套 (360p)',
      '贵州卫视 (576p)',
      '贵州卫视 (576p)',
      '贝爷影厅',
      '西藏藏語 (576p)',
      '西藏卫视 (576p)',
      '蕪湖教育頻道 (576p)',
      '萍鄉教育頻道 (480p)',
      '莒縣電視一套 (576p)',
      '莒縣圖文頻道 (720p)',
      '芜湖生活 (576p)',
      '芜湖新闻综合 (576p)',
      '芜湖公共 (576p)',
      '舟山群岛旅游 (720p)',
      '舟山新闻综合 (240p)',
      '舟山公共生活 (720p)',
      '肃州电视台 (1080p)',
      '耀才财经 (288p)',
      '耀才财经',
      '翡翠台',
      '美国狗狗宠物 (1080p)',
      '置业 (576p)',
      '继续教育 (576p)',
      '纯享4K (2160p)',
      '纪实人文 (1080p)',
      '精品电影 (1080p)',
      '粤语电影3',
      '粤语电影2',
      '娄底综合 (720p)',
      '篮球资讯 (576p)',
     '立法會直播 (Legislative Council Live) (720p)',
      'Acapulco Shore',
     '积石山电视台 (1080p)',
     '萬州綜合頻道 (576p)',
     '萬州科教頻道 (576p)',
     '萬州影視文藝 (576p)',
     '萬州三峽移民 (576p)',
     '福州电视台少儿频道 (FZTV-少儿) (1080p)',
     '福州广播电视台都市生活频道 (FZTV-3) (1080p)',
     '福州广播电视台新闻综合频道 (FZTV-1) (1080p)',
     '石家庄都市',
     '石家庄生活',
     '石家庄娱乐',
     '百事通体育5 (1080p)',
     '生活 (576p)',
     '甘肃卫视 (1080p)',
     '無綫財經·體育·資訊台',
     '無綫新聞台',
     '点掌财经 (712p)',
     '炫动卡通 (576p)',
     '灵台新闻综合 (1080p)',
     '滨海都市 (1080p)',
     '滨海新闻 (1080p)',
     '滨州测试 (576p)',
     '滨州新闻综合 (576p)',
     '滨州公共电视剧 (576p)',
     '滦县综艺 (576p)',
     '滦县综合 (576p)',
     '滁州科教 (450p)',
     '滁州新闻综合 (450p)',
     '滁州市广播电视台 公共频道 (400p)',
     '滁州公共 (450p)',
     '湘潭新闻综合 (720p)',
     '湘潭公共 (576p)',
     '湖南都市 (576p)',
     '湖南卫视 (1080p)',
     '湖北卫视 (1080p)',
     '深圳卫视 (1080p)',
     '涡阳新闻综合 (360p)',
     '海西州综合 (576p)',
     '海盐新闻 (720p)',
     '海南州藏語頻道 (480p)',
     '浙江钱江 (1080p)',
     '浙江影视 (720p)',
     '浙江少儿 (1080p)',
     '浙江国际 (1080p)',
     '浙江卫视 (1080p)',
     '浙江 Ⅰ 绍兴综合 (576p)',
     '浙江 Ⅰ 绍兴影视 (720p)',
     '洪雅新闻综合 (1080p)',
     '河源综合 (540p)',
     '河源公共 (540p)',
     '河南卫视 (576p)',
     '河北都市',
     '河北经济',
     '河北影视',
     '河北卫视 (576p)',
     '河北农民 (576p)',
     '河北农民 (360p)',
     '河北农民',
     '河北公共',
     '沧县电视综合 (576p)',
     '沧县电视二套 (576p)',
     '江西卫视 (1080p)',
     '江苏教育 (576p)',
     '江苏影视 (576p)',
     '江苏城市 (576p)',
     '新疆卫视 (540p)',
     '敦煌电视台 (1080p)',
     '東光一套 (576p)',
     '武汉外语 (576p)',
     '武汉经济 (360p)',
     '欧美大片2',
     '徐州-4 (1080p)',
     '弈坛春秋 (576p)',
     '广东卫视 (1080p)',
     '山西影视',
     '少儿科教',
     '宜章新闻综合 (576p)',
     '宁夏卫视 (576p)',
     '天才衝衝衝 (1080p)',
     '吉林卫视 (540p)',
     '南川旅游经济 (360p)',
     '林正英',
     '東森購物60 (480p)',
     '東光綜藝 (576p)',
     '東光二套 (576p)',
     '智慧教育 (576p)',
     '家庭影院 (1080p)',
     '安徽卫视 (1080p)',
     '安徽 Ⅰ 铜陵综合 (720p)',
     '安徽 Ⅰ 铜陵公共 (720p)',
     '安徽 Ⅰ 郎溪综合台 (1080p)',
     '孤注一掷清晰版',
     '吉州新聞綜合 (1080p)',
     '南京十八 (576p)',
     '刘德华',
     '凤凰中文 (720p)',
     '内蒙古卫视 (576p)',
     '内蒙古卫视 (576p)',
     '内江公共 (720p)',
     'Almaya TV (720p)',
     'Bac Lieu TV (1080p)',
     'Badass novelas',
     '云南卫视 (576p)',
     '永新电视一套 (576p)',
     '浙江少儿',
     '江苏卫视 (1080p)',
     '江苏公共 (576p)',
     '江苏体育 (576p)',
     '江苏卫视 (1080p)',
     '江苏公共 (576p)',
     '江苏体育 (576p)',
     '江苏卫视 (1080p)',
     '江苏公共 (576p)',
     '江苏体育 (576p)',
     '江苏体育 (576p)',
     '永新电视三套 (576p)',
     '武汉文体 (480p)',
     '欧美大片3',
     '欧美大片1',
     '星空衛視 (576p)',
     '新疆卫视 (576p)',
     '凤凰香港 (720p)',
     '内江综合 (720p)',
     '内江科教 (720p)',
     '兴隆综合',
     '侨乡 (1080p)',
     'Terra Mater WILD',
     'The Addams Family',
     'The Outpost',
     'Top Gear',
     'RoboCop',
     'Rules of Engagement',
     'Sam & Cat',
     "Schitt's Creek: David & Patrick!",
     'School of Rock',
     'See Dad Run',
     'Shades of Black',
     '抚州公共 (270p)',
     '房山电视台 (576p)',
     '徐州贾汪旅游 (576p)',
     '徐州經濟生活 (1080p)',
     '徐州新聞綜合 (1080p)',
     '徐州公共頻道 (1080p)',
     '徐州-3 (1080p)',
     '徐州-3 (1080p)',
     '徐州-1 (1080p)',
     '广东 ‖ 清新综合台 (1080p)',
     '平乡电视台 (576p)',
     '岷县电视台 (1080p)',
     '岳阳公共 (576p)',
     '山西经济',
     '山西法治',
     '山西文体',
     '奇妙電視 (720p)',
     '嘉佳卡通 (576p)',
     '吉林卫视 (360p)',
     '云南 Ⅰ 文山公共台 (1080p)',
     '之江纪录',
     '亳州新聞頻道 (360p)',
     '亳州农村 (360p)',
     '京视剧场 (576p)',
     '交城電視台 (576p)',
     '亚太台 (480p)',
     '五星体育 (720p)',
     '吉林卫视 (576p)',
     '台视 (720p)',
     '发现之旅 (576p)',
     '双峰电视一套 (360p)',
     '厦门卫视 (576p)',
     '博斯高球台',
     '南川新闻综合 (360p)',
     '南京生活 (720p)',
     '南京新闻综合 (720p)',
     '南京教科 (576p)',
     '南京娱乐 (720p)',
     '南京十八 (720p)',
     '南京信息 (720p)',
     '华亭电视台 (1080p)',
     '江津经济生活 (480p)',
     '江津新闻综合 (480p)',
     '江津文化旅游 (576p)',
     '永新电视二套 (576p)',
     '广西卫视 (576p)',
     '山西卫视 (576p)',
     '山东卫视 (1080p)',
     '山东卫视 (720p)',
     '宿迁公共 (480p)',
     '家庭理财 (576p)',
     '安徽 Ⅰ 淮北综合 (720p)',
     '安徽 Ⅰ 淮北公共 (720p)',
     '宇哥电影',
     '宁夏卫视 (540p)',
     '女性时尚 (576p)',
     '天津卫视 (1080p)',
     '大爱海外 (720p)',
     '四川卫视 (576p)',
     '四川公共 (720p)',
     'Willkommen bei den Louds',
     'Action Sports',
     'Are We There Yet?',
     'Auf Achse',
     'Awkward',
     'Baywatch',
     'Bellator MMA',
     'Best Action Movies (1080p)',
     'Best Classic Movies (1080p)',
     'Best Drama Movies (1080p)',
     'Beverly Hills 90210',
     'Big Time Rush',
     'Bloomberg',
     "Blue's Clues",
     'Rauchende Colts',
     'Xtrema Cine Family (274p)',
     'Xtreme Outdoor Presented by HISTORY',
     'Xtrema Terror',
     'Xtrema Series (360p)',
     'Xtrema Retro Series (360p)',
     'Xtrema Retro Cartoons (360p)',
     'Xtrema Cine Suspenso (360p)',
     'Xtrema Cine Latino (360p)',
     'Xtrema Cine Hindi (272p)',
     'Xtrema Cine Drama (360p)',
     'Xtrema Cine Comedy (344p)',
     'Xtrema Cine Clasico',
     'Xtrema Cine Asia (268p)',
     'Xtrema Cartoons',
     'Xtrema Anime (360p)',
     'Xtrema Animal',
     'Xtrema Accion',
     'Zoey 101',
     'Zoy TV Music',
     'Zoy TV Sports 5',
     'Zoy TV Sports 3',
     'Zoy TV Sports 2',
     'Zoy TV Sports 1',
     'Scooore',
     'Shop LC (1080p)',
     'SLO 1',
     'SLO 2',
     'SLO 3',
     'TV Birigui (640p)',
     'TV Diário do Sertão (720p)',
     'GTN South Florida (1080p)',
     'GTN New York (1080p)',
     'KMIZ-DT2 (720p)',
     'KRCA-TV Estrella Los Angeles (720p)',
     'Pickers & Pawn',
     'Mission Impossible',
     'Monster Jam',
     'MorroTV (414p)',
     'Motorvision',
     'MTV German Music',
     'MTV Ridiculousness',
     'MTV The Hills',
     'MTV Unplugged',
     'Myth Hunters',
     'Naruto',
     'NCIS',
     'NHL',
     'Nick Space',
     'Nick Ukraine',
     'Fluss-Monster',
     'ТВЦ',
     '万州科教 (576p)',
     '上海金山电视台 (270p)',
     '丰宁综合',
     '云南 Ⅰ 红河综合台 (1080p)',
     '云南卫视 (540p)',
     '云南卫视 (1080p)',
     '云南都市 (1080p)',
     '仙桃新聞綜合 (576p)',
     '仙桃生活文體 (576p)',
     '任丘文娱',
     '优漫卡通 (576p)',
     '优视 (720p)',
     '内蒙古 (576p)',
     '凤凰中文 (576p)',
     '凤凰资讯 (576p)',
     '凤凰资讯 (720p)',
     '动作电影 (1080p)',
     '动画王国 (1080p)',
     'Auto Motor Sport',
     'Baraza TV Relaxing (720p)',
     'BBC Doctor Who',
     'BBC Doctor Who Classic (720p)',
     'BBC Drama',
     'BBC Drama (720p)',
     'BBC Earth',
     'BBC Food',
     'BBC Home & Garden',
     'BBC News',
     'BBC News (720p)',
     'Outside TV (1080p)',
     'PBS America (720p)',
     'Pro TV News (1080p)',
     'PROFI 24 TV (576p)',
     'Reich und Schön',
     'Reklama TV (720p)',
     'Relax',
     'Reportero TV',
     'Republic of Doyle',
     'RTL 102.5 Best (1080p)',
     'RTL 102.5 Bro&Sis (1080p)',
     'RTL 102.5 Doc (1080p)',
     'RTL 102.5 Extra (1080p)',
     'RTL 102.5 Napulé (1080p)',
     'RTL 102.5 TV (1080p)',
     'RTL (Germany) (576p)',
     'RTL Croatia (1080p)',
     'RTL Gold (576p)',
     'RTL Harom (1080p)',
     'RTL Hungary (1080p)',
     'RTL Ketto (1080p)',
     'RTL Kockica (1080p)',
     'ABC News Live 2 (720p)', //duplicate
     'ABC News Live 3 (720p)', //duplicate
     'ABC News Live 4 (720p)', //duplicate
     'ABC News Live 5 (720p)', //duplicate
     'ABC News Live 6 (720p)', //duplicate
     'ABC News Live 7 (720p)', //duplicate
     'ABC News Live 9 (720p)', //duplicate
     'Amigos TV Chiquimula (480p)',
     'BBC Travel',
     'Becker',
     'Bewegte Männer',
     'Beyblade Burst',
     'Beyond History',
     'Big Brother Canada',
     'Big City TV',
     'Billiard TV',
     'Billiards+ (1080p)',
     'BIZ Cinema (1080p)',
     'BIZ Music (1080p)',
     'Sabrina Total verhext!',
     'Súbela (720p)',
     'Sünger Bob Kare Pantolon',
     'Supermarket Sweep',
     "Takeshi's Castle",
     'Täterjagd',
     'Teen Nick',
     'Teenage Mutant Ninja Turtles',
     'Teenage Mutant Ninja Turtles Italy (720p)',
     'Telemundo Telenovelas Clásicas',
     'Telenovela ZDF',
     'The Beverly Hillbillies',
     'The Design Network Europe',
     'The Dick Van Dyke Show',
     'MOVIEDOME',
     'Movify Kino (576p)',
     'Movies for Gamers',
     'MTV Approved Hip Hop',
     'MTV Spankin’ New',
     'Murder She Wrote',
     'Naturescape',
     'Nick HD+ (1080p)',
     'Nick Danger',
     'NickToons UK (576p)',
     'Out TV Proud',
     'OUTtv Proud',
     'Outside TV',
     'Pacific Blue',
     'Praxis Bülowbogen',
     'PRIDEtv',
     'Qui veut gagner des millions?',
     'Qyzyljar (720p)',
     'Qyzylorda (720p)',
     'R 101 Italia IT (576p)',
     'R television TV (720p)',
     'Raajje TV (480p)',
     'Racing America (720p)',
     'Radio 3 Cadena Patagonia (720p)',
     'Radio 21 TV (720p)',
     'Radio 105 TV (576p)',
     'Real Disaster Channel',
     'Real Housewives Vault',
     'Real Life on Verizon',
     'River Monsters',
     'Sailor Moon',
     'The Love Boat',
     'The Price is Right',
     'HauntTV',
     'Haunt TV Canada (1080p)',
     'Haunt TV',
     'Helwa TV (1080p)',
     'Hot Ones',
     'Hours to Kill – Zeitachse des Todes',
     'Hours to Kill Zeitachse des Todes',
     'I Love Lucy',
     'iFilm 2',
     'iFilm Arabic',
     'iFilm English',
     'iFilm Persian',
     'INA 70',
     'InWild (720p)',
     'Iqra TV (576p)',
     'Jewish Life Television',
     'Just.fishing',
     'KultKrimi',
     "Let's Make A Deal",
     'Live Music',
     'Living Springs TV (1080p)',
     'Love Boat',
     'Love & Hip Hop 2',
     'LoveworldSAT',
     'LoveWorld USA (720p)',
     'Macarena FM (720p)',
     'MacGyver',
     'MacGyver Italy (720p)',
     'Mad About You',
     '49 канал (Новосибирск)',
     'AKIBA PASS TV Anime: Sport',
     'PFL MMA',
     'La Rosa de Guadalupe',
     'MTV Cribs',
     'MTV Dating',
     'MTV en Español',
     'MTV Flow Latino',
     'MTV HD',
     'MTV Hits France (720p)',
     'MTV Live',
     'MTV Live (720p)',
     'MTV Music',
     'MTV Reality',
     'MTV Serie',
     "MTV Spankin' New",
     'MTV Teen Mom',
     'MTV The Hills (OV)',
     'MTV The Shores',
     'mtvU (480p)',
     'Multicanal Televisión (720p)',
     'Naxatra News (720p)',
     "Nazali's HDTV (1080p)",
     'NBS TV',
     'NBT 2HD (720p)',
     'Adventure Sports TV',
     'Alle hassen Chris',
     'Alan Becker TV',
     'ANTV (1080p)',
     'Baby Alive Français',
     'Baby Shark TV',
     'BabyFirst (1080p)',
     'Bloomberg TV Australia (270p)',
     'Bloomberg TV EMEA Live Event (720p)',
     'Bloomberg TV Europe (720p)',
     'Bloomberg TV US Live Event (720p)',
     'Bloomberg TV US Politics Live Event (720p)',
     'Bloomberg TV+',
     'Bloomberg HT',
     'Bloomberg Television',
     'Bloomberg TV Asia (720p)',
     'Bloomberg TV Asia Live Event (720p)',
     'Aastha (480p)',
     'Aastha Bhajan (480p)',
     'ABC 5',




     

 

   



      
    ];
    const DEFAULT_LOGO = 'https://laniakea-multimedia.s3.us-east-1.amazonaws.com/laniakea.tv/Frame+57.png';
    const PLACEHOLDER_LOGOS = [
      'https://od.lk/s/MF8yMzMxMzM1NzJf/LoneStar_250x250.png',
      'https://i.imgur.com/dGgmOY0.png',
      'https://tv.taurer.org/iptv_content/logos/KULINAR_TEMP.png',
      'https://i.imgur.com/JoPywOp.png',
      'https://i.imgur.com/kopXiwA.png',
      'https://i.imgur.com/sd7BX9H.png',
      'https://i.imgur.com/O4564aX.png',
      'https://i.imgur.com/KiIzWmO.jpg',
      'https://upload.wikimedia.org/wikipedia/en/6/66/The_Grio_logo.png',
      'https://i.imgur.com/ob1GhbC.jpg',
      'https://static.wikia.nocookie.net/logopedia/images/0/01/Antena_Monden_2022.png/revision/latest/scale-to-width-down/300',
      'https://play-lh.googleusercontent.com/FHPrsr391mPFqKfTq6ZdvWPZNVfsZeTAVQ8rjQF-NosnRdNipUy5xa43RWkiF4tGcQU',
      'https://upload.wikimedia.org/wikipedia/it/thumb/9/99/Ondatv.jpg/512px-Ondatv.jpg',
      'https://i.postimg.cc/WbrWPpVg/BTV-Bac-Ninh-2021.png',
      'https://i.postimg.cc/xT9jJXZ0/TBK-logo-2021.png',
      'https://epgx.site/p/tvoetv-humor.png',
      'https://i.imgur.com/KLYsKKC.png',
      'https://i.imgur.com/exdDHml.png',
      'https://upload.wikimedia.org/wikipedia/en/5/54/WBAL_Horizontal_Logo_2013.png',
      'https://upload.wikimedia.org/wikipedia/en/thumb/3/33/PBS_logo.svg/512px-PBS_logo.svg.png',
      'https://i.imgur.com/HDGfDkL.png',
      'https://i.imgur.com/mxsBZaC.png',
      'https://i.imgur.com/9w9SSIC.png',
      'https://i.imgur.com/N7HCtbe.png',
      'https://i.imgur.com/NJYEYE3.png',
      'https://upload.wikimedia.org/wikipedia/commons/7/71/Nothing_whitespace_blank.png',
      'https://i.imgur.com/zOXxzrU.png',
      'https://i.imgur.com/LdGWsGj.png',
      'https://i.imgur.com/8G1S2c2.png',
      'https://i.imgur.com/sYvwHWI.png',
      'https://i.imgur.com/mzqA5YX.png',
      'https://i.imgur.com/NFFQNhq.png',
      'https://i.imgur.com/mh1I0Gz.png',
      'https://i.imgur.com/jkOgbLw.png',
      'https://i.imgur.com/LcJZVV5.png',
      'https://i.imgur.com/GGdnGqH.png',
      'https://i.imgur.com/NBWyJJQ.png',
      'https://kabeloperatori.neplpadome.lv/lv/assets/images/Programmu%20logo/hochuvse.png',
      'https://i.imgur.com/bBVEP0S.png',
      'https://i.imgur.com/E9vX12j.jpg',
      'https://i2.paste.pics/8c4977628c2855095b6b9bff9a9f2a1d.png',
      'https://i.imgur.com/3V6ads0.png',
      'https://spilebanon.com/img/spi-logo.jpg',
      'https://i.imgur.com/yijitpa.png',
      'https://i2.paste.pics/91920f8c1ac64beaaf70f7fbc09982c8.png',
      'https://i.imgur.com/IaRaabJ.jpg',
      'https://upload.wikimedia.org/wikipedia/en/thumb/9/9a/Aljazeera_Mubasher.svg/512px-Aljazeera_Mubasher.svg.png',
      'https://comteco.com.bo/img/upload/canales/abya-yala.png',
      'https://i.imgur.com/6oNx5F0.png',
      'https://imgur.com/nKAUCLF.jpg',
      'https://i.imgur.com/yxEbZqn.jpg',
      'https://i.imgur.com/QbkW7zY.png',
      'https://i.imgur.com/removed.png',
      'https://i.imgur.com/8sQ5mfL.png',
      'https://i.imgur.com/pXOyNEy.png',
      'https://i.imgur.com/ZZEvehR.png',
      'https://i.imgur.com/4iEFQjd.png',
      'https://i2.paste.pics/',
      'https://i.imgur.com/e5fdQZl.png',
      'https://i.imgur.com/Xuo2fMZ.png',
      'https://i.imgur.com/OgEeQzl.jpg',
      'https://od.lk/s/MF8yMjg0MDgzNDBf/TheArchive_300x300.png',
      'https://i.imgur.com/FQCVaSR.png',
      'https://i.imgur.com/8vXRrRe.jpg',
      'https://od.lk/s/MF8yMjk3NjU0OTJf/ABCNewsLive_2021_200x200.png',
      'https://i.imgur.com/2xRKezH.png',
      'https://teveglobo.s3.us-east-2.amazonaws.com/Logo.png',
      'https://i.imgur.com/OU2AkQs.png',
      'https://upload.wikimedia.org/wikipedia/commons/thumb/9/97/Tigo_Sports_logo.svg/512px-Tigo_Sports_logo.svg.png',
      'https://upload.wikimedia.org/wikipedia/en/0/0a/WDEF_logo_2015.png',
      'https://upload.wikimedia.org/wikipedia/commons/6/6b/PBSworld.png',
      'https://www.artidijitalmedya.com/image/400x400/cdn/channels/v1/logo_151.png',
      'https://i.imgur.com/o0VJYrn.png',
      'https://i.imgur.com/DFEKx5N.png',
      'https://i.imgur.com/',
      'https://i.imgur.com/yxEbZqn.jpg',
      'https://i.imgur.com/EvIDGyR.png',
      'https://pbs.twimg.com/profile_images/1587656647803113472/tANAElTS_400x400.jpg',
      'https://i.imgur.com/wHb9BLd.png',
      'https://f9q4g5j6.ssl.hwcdn.net/5fc81016d98cab623846a4f3',
      'https://i.imgur.com/zYH4IsV.png',
      'https://i.imgur.com/HY64Nhe.png'


   


    ];
  
    const channels: { name: string; logo: string; url: string }[] = [];
    const lines = data.split('\n');
  
    // Loop through lines to find #EXTINF lines and the subsequent URL line
    for (let i = 0; i < lines.length; i++) {
      const line = lines[i].trim();
  
      // Check if line starts with #EXTINF:
      if (line.startsWith('#EXTINF:')) {
        const meta = line;
        const urlLine = lines[i + 1] ? lines[i + 1].trim() : '';
  
        if (!urlLine || urlLine.startsWith('#EXTINF')) {
          // No valid URL or next line is another #EXTINF
          continue;
        }
  
        // Extract channel name from text after the last comma
        let name = 'Unknown Channel';
        const nameMatch = meta.match(/,(.*)$/);
        if (nameMatch && nameMatch[1]) {
          name = nameMatch[1].trim();
        }
  
        // Extract tvg-logo from the meta line
        let logo = '';
        const logoMatch = meta.match(/tvg-logo="([^"]*)"/);
        if (logoMatch && logoMatch[1]) {
          logo = logoMatch[1].trim();
        }
  
        const channelUrl = urlLine;
  
        // Use DEFAULT_LOGO if no logo or if logo matches either placeholder
        channels.push({
          name,
          logo: !logo || PLACEHOLDER_LOGOS.includes(logo) ? DEFAULT_LOGO : logo,
          url: channelUrl,
        });
      }
    }
  
    // Filter out unwanted channels
    const filteredChannels = channels.filter((ch) => {
      // If the name is in REMOVE_LIST, contains "[Not 24/7]", "[Geo-blocked]", or "Pluto TV", skip it
      if (
        REMOVE_LIST.includes(ch.name) ||
        ch.name.includes('KultKrimi') ||
        ch.name.includes('iCarly') ||
        ch.name.includes('[Not 24/7]') ||
        ch.name.includes('[Geo-blocked]') ||
        ch.name.includes('Pluto TV') ||
        ch.name.includes('Alerte') ||
        ch.name.includes('Xinjiang') ||
        ch.name.includes('Reno') ||
        ch.name.includes('XITE') ||
        ch.name.includes('beIN') ||
        ch.name.includes('Foochow') ||
        ch.name.includes('VOD') ||
        ch.name.includes('HTB') ||
        ch.name.includes('Rakuten') ||
        ch.name.includes('Super!') ||
        ch.name.includes('South Park') ||
        ch.name.includes('World Poker Tour') ||
        ch.name.includes('Chrome') ||
        ch.name.includes('Nosey') ||
        ch.name.includes('Nosey') ||
        ch.name.includes('Preschool') ||
        ch.name.includes('江苏体育') ||
        ch.name.includes('Angel TV') ||
        ch.name.includes('TVMatic') ||
        ch.name.includes('QTV') ||
        ch.name.includes('TVP') || 
        ch.name.includes('Totally Turtles') || 
        ch.name.includes('SpongeBob') || 
        ch.name.includes('Star Trek') || 
        ch.name.includes('Überflieger') || 
        ch.name.includes('Transformers') || 
        ch.name.includes('SPIEGEL TV') || 
        ch.name.includes('Avatar') || 
        ch.name.includes('PlanetaTV') || 
        ch.name.includes('Bolton') || 
        ch.name.includes('Rawhide') || 
        ch.name.includes('América Estéreo') || 
        ch.name.includes('TVG') || 
        ch.name.includes('Планета') || 
        ch.name.includes('Yu-Gi-Oh!')
      ) {
        return false;
      }
  
      // Otherwise, keep it
      return true;
    });
    let channelsIframe = [];
    for (let i = 0; i < 99 && i < filteredChannels.length; i++) {
      channelsIframe.push(filteredChannels[i]);
    }
    window.parent.postMessage( channelsIframe , '*');
    console.log('filteredChannels', filteredChannels);

    this.filteredChannels=filteredChannels;
    return filteredChannels;
  }
  
  
  /**
   * Fetch and validate the playlist content (if needed)
   */
  async fetchAndValidatePlaylist(url: string): Promise<string> {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Failed to fetch playlist: HTTP ${response.status}`);
    }
  
    const content = await response.text();
    if (!content.startsWith('#EXTM3U')) {
      throw new Error('Invalid playlist format: Missing #EXTM3U header.');
    }
  
    return content;
  }
  
  /**
   * Play (or download) the selected channel with support for Blob URLs via a CORS proxy.
   * Note: For .m3u8 streams, you typically want Hls.js, not a direct Blob approach.
   */
   async playChannel(url: string) {
    const video = document.getElementById('video-player') as HTMLVideoElement;
    if (!video) {
      console.error('Video element not found.');
      return;
    }
  
    // Clean up any previous Hls.js instance
    if ((window as any).hlsInstance) {
      (window as any).hlsInstance.destroy();
      (window as any).hlsInstance = null;
    }
  
    // Directly use the raw URL (no proxy)
    const directUrl = url;
  
    if (Hls.isSupported()) {
      console.log('Using Hls.js with M3U8 URL:', directUrl);
      const hls = new Hls();
      (window as any).hlsInstance = hls;
  
      hls.loadSource(directUrl);
      hls.attachMedia(video);
  
      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        console.log('HLS manifest parsed, playing...');
        this.videoUrl = directUrl;
        video.play().catch(err => console.error('Play error:', err));
      });
  
      hls.on(Hls.Events.ERROR, (event, data) => {
        console.error('Hls.js error:', data);
        if (data.fatal) {
          switch (data.type) {
            case Hls.ErrorTypes.NETWORK_ERROR:
              // try to recover network error
              hls.startLoad();
              break;
            case Hls.ErrorTypes.MEDIA_ERROR:
              // try to recover media error
              hls.recoverMediaError();
              break;
            default:
              // cannot recover
              hls.destroy();
              break;
          }
        }
      });
    }
    // If iOS/Safari can play .m3u8 natively
    else if (video.canPlayType('application/vnd.apple.mpegurl')) {
      console.log('Using native HLS in Safari...');
      video.src = directUrl;
      this.videoUrl = directUrl;
      video.play().catch(err => console.error('Play error (native):', err));
    } else {
      console.error('No HLS support in this browser.');
    }
  }
  
  
  
  /**
   * Rewrite playlist URLs if needed (not strictly necessary for raw .m3u8 links).
   */
  rewritePlaylistUrls(playlist: string, baseUrl: string, proxyUrl: string): string {
    return playlist.replace(
      /(https?:\/\/[^\s]+|[\w\-\.\/]+\.m3u8|[\w\-\.\/]+\.ts)/g,
      (match) => {
        if (match.startsWith('http')) {
          return proxyUrl + encodeURIComponent(match);
        } else {
          const absoluteUrl = new URL(match, baseUrl).toString();
          return proxyUrl + encodeURIComponent(absoluteUrl);
        }
      }
    );
  }
  /**
   * Close the player and return to the channel list
   */
  closePlayer() {
    const video = document.getElementById('video-player') as HTMLVideoElement;
    if (video) {
      video.pause();
      video.src = '';
      this.videoUrl = null;
    }
  }
  
  
}
